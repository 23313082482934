.form-check {
  position: relative;
}

// checkbox input right

.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;

  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }

  .form-check-label {
    display: block;
  }
}

[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  &::placeholder {
    text-align: left /*rtl: right*/
  ;
  }
}

.form-check {
  position: relative;
  text-align: left /*rtl: right*/
;
}

.input-group-text {
  margin-bottom: 0;
}


.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}
