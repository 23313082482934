//
// breadcrumb.scss
//


// BreadcrumbDispositivos item arrow
.breadcrumb-item {
  > a {
    color: var(--#{$prefix}secondary-color);
  }

  + .breadcrumb-item {
    &::before {
      font-family: "Material Design Icons";
    }
  }
}
  